@import "./colors.scss";
@import "./links.scss";

.menu-header {
    background-color: $background-secondary;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: $text-secondary;
    height: 100px; // TODO: maybe make a sizes file or something
  }

  .menu-link {
    @include link($text-secondary, $background-tertiary);
  }
  
  
  .menu-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .menu-list-item {
    display: inline;
  }
    