@import "./colors.scss";

.Home {
    text-align: center;
  }
  
.home-body {
    background-color: $background-primary;
    color: $text-primary;
  }
  